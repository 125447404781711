<template>
  <dx-util-popup
    ref="lineChartPopupRef"
    :drag-enabled="false"
    height="auto"
    width="auto"
    :close-on-outside-click="true"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="lineChartPopupShown"
    @hidden="lineChartPopupHidden"
  >
    <div>
      <DxChart
        id="chart"
        :data-source="rawChartData"
        palette="Bright"
        style="max-width: 1200px;"
      >
        <DxCommonSeriesSettings
          type="line"
          argument-field="title"
        />
        <DxSeries
          v-for="group in groups"
          :key="group.value"
          :value-field="group.value"
          :name="group.name"
        />
        <DxMargin :bottom="20" />
        <DxArgumentAxis
          :value-margins-enabled="false"
          discrete-axis-division-mode="crossLabels"
        >
          <DxGrid :visible="true" />
        </DxArgumentAxis>
        <DxLegend
          vertical-alignment="bottom"
          horizontal-alignment="center"
          item-text-position="bottom"
        />
        <DxExport :enabled="true" />
        <DxTitle :text="`Monthly Revenue and Cost` ">
          <DxSubtitle :text="reportItem" />
        </DxTitle>
        <DxTooltip :enabled="true" :z-index="99999" />
      </DxChart>
    </div>
  </dx-util-popup>
</template>

<script>
import amazonReportService from '@/http/requests/reports/amazonReportService'
import AmazonReportFilter from '@/http/models/search-filters/AmazonReportFilter'
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxExport,
  DxGrid,
  DxMargin,
  DxLegend,
  DxTitle,
  DxSubtitle,
  DxTooltip,
} from 'devextreme-vue/chart'
import { v4 as uuidv4 } from 'uuid'
import reportTypeEnum from '@/enums/reportTypeEnum'
// import { Notify } from '@/@robustshell/utils'

const groups = [
  { id: uuidv4(), value: 'totalCosts', name: 'Total Costs' },
  { id: uuidv4(), value: 'totalSales', name: 'Total Sales' },
]
export default {
  components: {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxExport,
    DxGrid,
    DxMargin,
    DxLegend,
    DxTitle,
    DxSubtitle,
    DxTooltip,
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    reportItem: {
      type: String,
      default: '',
    },
    storeId: {
      type: Number,
      default: null,
    },
    reportType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      popupTitle: '',
      rawChartData: [],
      groups,
    }
  },
  computed: {
    lineChartPopup() {
      return this.$refs.lineChartPopupRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.lineChartPopup.show()
        this.getRawChartData()
      },
    },
  },
  methods: {
    async getRawChartData() {
      let filters = AmazonReportFilter.getFilters()
      filters = { ...filters, storeId: this.storeId }
      let response

      if (this.reportType === reportTypeEnum.ASIN.value) {
        filters = { ...filters, asin: this.reportItem }
        response = await amazonReportService.getAmazonMonthlyReport(filters)
      }
      if (this.reportType === reportTypeEnum.MSKU.value) {
        filters = { ...filters, msku: this.reportItem }
        response = await amazonReportService.getAmazonMonthlyReport(filters)
      }
      if (this.reportType === reportTypeEnum.CATEGORY.value) {
        filters = { ...filters, productCategory: this.reportItem }
        response = await amazonReportService.getAmazonMonthlyReport(filters)
      }
      if (this.reportType === reportTypeEnum.SUPPLIER.value) {
        filters = { ...filters, supplier: this.reportItem }
        response = await amazonReportService.getAmazonMonthlyReport(filters)
      }
      this.rawChartData = response.content
    },
    lineChartPopupShown() {
      this.popupTitle = 'Monthly Cost & Revenue'
    },
    lineChartPopupHidden() {
    // Clear form when popup is closed
    },
    closePopup() {
      this.lineChartPopup.hide()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
